import { default as about_45passWM04FFHCR7Meta } from "/codebuild/output/src3615337598/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenaviVySrj1SBqOMeta } from "/codebuild/output/src3615337598/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementHcwbUGKMjwMeta } from "/codebuild/output/src3615337598/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45alias66aWnQDqScMeta } from "/codebuild/output/src3615337598/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmW3WQpEBInkMeta } from "/codebuild/output/src3615337598/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliasNYhQPEzlshMeta } from "/codebuild/output/src3615337598/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editSQrSEwv9J2Meta } from "/codebuild/output/src3615337598/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexq6He80Ay86Meta } from "/codebuild/output/src3615337598/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmJdJlTPNbASMeta } from "/codebuild/output/src3615337598/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indextiZW5rlNpdMeta } from "/codebuild/output/src3615337598/src/src/pages/card/create/index.vue?macro=true";
import { default as indexBjzsV4wRVNMeta } from "/codebuild/output/src3615337598/src/src/pages/card/index.vue?macro=true";
import { default as completeOENB5R68fRMeta } from "/codebuild/output/src3615337598/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirm9zpe2bdXtQMeta } from "/codebuild/output/src3615337598/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as register1DVuCgjmwIMeta } from "/codebuild/output/src3615337598/src/src/pages/contact-form/register.vue?macro=true";
import { default as contactKW1mdvwrMfMeta } from "/codebuild/output/src3615337598/src/src/pages/contact.vue?macro=true";
import { default as _91id_93lKBrGvoQqhMeta } from "/codebuild/output/src3615337598/src/src/pages/coupon/[id].vue?macro=true";
import { default as index2IJeyR8mO5Meta } from "/codebuild/output/src3615337598/src/src/pages/coupon/index.vue?macro=true";
import { default as faqTtgUndpyNLMeta } from "/codebuild/output/src3615337598/src/src/pages/faq.vue?macro=true";
import { default as indexcizh58xH9AMeta } from "/codebuild/output/src3615337598/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93vieVGy1T8AMeta } from "/codebuild/output/src3615337598/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexoM0LHckll5Meta } from "/codebuild/output/src3615337598/src/src/pages/index.vue?macro=true";
import { default as maintenanceLS1jHrRTdiMeta } from "/codebuild/output/src3615337598/src/src/pages/maintenance.vue?macro=true";
import { default as completeDXNAJlELxYMeta } from "/codebuild/output/src3615337598/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmJdm6Kr7k2MMeta } from "/codebuild/output/src3615337598/src/src/pages/membership/confirm.vue?macro=true";
import { default as registerrdgj5SonnoMeta } from "/codebuild/output/src3615337598/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codeBgFY8y1O0CMeta } from "/codebuild/output/src3615337598/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmKRcoluTHkuMeta } from "/codebuild/output/src3615337598/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as registerlmL7DVkq4gMeta } from "/codebuild/output/src3615337598/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menue9DjN7oZbQMeta } from "/codebuild/output/src3615337598/src/src/pages/menu.vue?macro=true";
import { default as indexzKYsH3STNJMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/index.vue?macro=true";
import { default as indexfZcxcuniG2Meta } from "/codebuild/output/src3615337598/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as sampleJ1CCSE0kxlMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as complete5AkQEvlbxjMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeZVx2eV40toMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexXWSX0HwMIoMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexA9GJVY6QNNMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as complete2zVYUumolSMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmQz7aQY2yd8Meta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as index4dl3tu3MfNMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updatenrr6Y8m001Meta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmuVxShf3b0CMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexBt4DT6VLOtMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as update4xOra1jqd1Meta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completeisljE90kdTMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updateJSdlkwFHdgMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexCQhyhFnZimMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93Cib3hUaqBiMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completeKNO4e2Am26Meta } from "/codebuild/output/src3615337598/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmttRpTlRdlSMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completepJAN2yhFN8Meta } from "/codebuild/output/src3615337598/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirm5fgbihZ5IhMeta } from "/codebuild/output/src3615337598/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentbRvLNGkTT2Meta } from "/codebuild/output/src3615337598/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_930WVEizTPxaMeta } from "/codebuild/output/src3615337598/src/src/pages/notice/[id].vue?macro=true";
import { default as indexfJb5BSRFZVMeta } from "/codebuild/output/src3615337598/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_939sXCBwvDbkMeta } from "/codebuild/output/src3615337598/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementCsv3cUqNXuMeta } from "/codebuild/output/src3615337598/src/src/pages/pass-agreement.vue?macro=true";
import { default as completeLh7veDgQkOMeta } from "/codebuild/output/src3615337598/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeIxNLBXMnIeMeta } from "/codebuild/output/src3615337598/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordRQWGYwtECxMeta } from "/codebuild/output/src3615337598/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailDPIOfULrSJMeta } from "/codebuild/output/src3615337598/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codesk5ZpqrvMCMeta } from "/codebuild/output/src3615337598/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexq0z1W6zYx7Meta } from "/codebuild/output/src3615337598/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codejdu3XozrZAMeta } from "/codebuild/output/src3615337598/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as index3URvXpC9cHMeta } from "/codebuild/output/src3615337598/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialICFKedBn05Meta } from "/codebuild/output/src3615337598/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45alias66aWnQDqScMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmW3WQpEBInkMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasNYhQPEzlshMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editSQrSEwv9J2Meta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmJdJlTPNbASMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indextiZW5rlNpdMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeOENB5R68fRMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirm9zpe2bdXtQMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: register1DVuCgjmwIMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: index2IJeyR8mO5Meta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexcizh58xH9AMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexoM0LHckll5Meta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeDXNAJlELxYMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmJdm6Kr7k2MMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registerrdgj5SonnoMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codeBgFY8y1O0CMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmKRcoluTHkuMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registerlmL7DVkq4gMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menue9DjN7oZbQMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexzKYsH3STNJMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexfZcxcuniG2Meta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sampleJ1CCSE0kxlMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexCQhyhFnZimMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completeKNO4e2Am26Meta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmttRpTlRdlSMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completepJAN2yhFN8Meta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirm5fgbihZ5IhMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentbRvLNGkTT2Meta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexfJb5BSRFZVMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_939sXCBwvDbkMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completeLh7veDgQkOMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeIxNLBXMnIeMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordRQWGYwtECxMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailDPIOfULrSJMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codesk5ZpqrvMCMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexq0z1W6zYx7Meta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codejdu3XozrZAMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: index3URvXpC9cHMeta || {},
    component: () => import("/codebuild/output/src3615337598/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src3615337598/src/src/pages/tutorial.vue")
  }
]