import { useLoginState } from '@/composables/store/useLoginStore';
import { useCompareVersions } from '@/composables/useCompareVersions';
import { useLoadingState } from '@/composables/store/useLoadingStore';

/** グローバルStateへ保持された値がなければ、Flutterから取得して保持するラッパー関数 */
const fetchAndSetStateWrapper = async <T>(
  state: Ref<T>,
  fetchFunc: () => Promise<T | undefined>,
  setFunc: (value: T) => void,
  defaultValue: T,
): Promise<void> => {
  if (!state.value) {
    const result = await fetchFunc();
    setFunc(result ?? defaultValue);
  }
};

export default defineNuxtPlugin(async () => {
  const {
    getUserId,
    getAuthToken,
    oldGetAuthToken,
    getCurrentVersion,
    createAccessKey,
    getIsTutorialViewed,
    getIsAgreed,
  } = useFlutterConnection();
  const {
    userId,
    authToken,
    accessKey,
    isTutorialViewed,
    isAgreed,
    currentVersion,
    setUserId,
    setAuthToken,
    setOldAuthToken,
    setAccessKey,
    setIsTutorialViewed,
    setIsAgreed,
    setCurrentVersion,
  } = useLoginState();

  await Promise.all([
    // API認証キー
    fetchAndSetStateWrapper(accessKey, createAccessKey, setAccessKey, ''),
    // チュートリアルを見たかどうか
    fetchAndSetStateWrapper(
      isTutorialViewed,
      getIsTutorialViewed,
      setIsTutorialViewed,
      false,
    ),
    // 利用規約同意の有無
    fetchAndSetStateWrapper(isAgreed, getIsAgreed, setIsAgreed, false),
    // ユーザーID
    fetchAndSetStateWrapper(userId, getUserId, setUserId, 0),
    // 現在のバージョン
    fetchAndSetStateWrapper(
      currentVersion,
      getCurrentVersion,
      setCurrentVersion,
      '',
    ),
  ]);

  const { isAppSupportCognitoAuth } = useCompareVersions();
  const authSupported = isAppSupportCognitoAuth();

  // 認証トークン
  if (authSupported) {
    await fetchAndSetStateWrapper(authToken, getAuthToken, setAuthToken, '');
  } else {
    await fetchAndSetStateWrapper(
      authToken,
      oldGetAuthToken,
      setOldAuthToken,
      '',
    );
  }
});
